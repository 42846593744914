import { render, staticRenderFns } from "./CompanyCardWidget.vue?vue&type=template&id=739543fc&scoped=true&"
import script from "./CompanyCardWidget.vue?vue&type=script&lang=ts&"
export * from "./CompanyCardWidget.vue?vue&type=script&lang=ts&"
import style0 from "../../assets/styles/CompanyCardWidget.scss?vue&type=style&index=0&id=739543fc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "739543fc",
  null
  
)

export default component.exports